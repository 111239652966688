import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'
import FormContactCareers from "components/formContactCareers";

class AccountExecutive extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Account Executive" description={'Sei un commerciale account competente in ambito Digital? Supportarci nello sviluppo del nostro business. Inviaci la tua candidatura.'} />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}>ACCOUNT<br/><Light>Account executive</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

                  <h3>Cerchiamo Account Executive per la nostra digital agency</h3>

                  <p>La figura risponderà alla direzione dell’agenzia e avrà la responsabilità di gestione in autonomia
                    il portfolio clienti e progetti che andrà a costruire.</p>

                  <p>Più dettagliatamente, la risorsa dovrà occuparsi di:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Sviluppare la rete clienti in accordo con le direttive commerciai dell'azienda</li>
                    <li>Ideare e proporre progetti digital in linea con i bisogni del cliente</li>
                    <li>Gestire il budget di progetto monitorando marginalità e
                      qualità dei deliverables in accordo con il Project Manager
                    </li>
                    <li>Assicurarsi della soddisfazione del cliente</li>
                  </ul>
                  <p>Requisiti fondamentali:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Esperienza di almeno 2 anni in ruolo analogo</li>
                    <li>Attitudine alla gestione di progetti digitali con focus su
                      comunicazione, UX e web marketing
                    </li>
                    <li>Conoscenza degli strumenti di analisi delle performance.
                    </li>
                    <li>Capacità di gestire più progetti e di operare per
                      obiettivi, in modo indipendente o con una supervisione minima.
                    </li>
                    <li>Conoscenza di sistemi di gestione come Jira, Trello,
                      Teamwork etc
                    </li>
                    <li>Buona predisoposizione commerciale</li>
                  </ul>
                  <p>Costituiranno titolo preferenziale:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Laurea e/o Master in Economia e Marketing e/o
                      Comunicazione.
                    </li>
                    <li>Conoscenza anche accademica di software quali CRM e ERP
                    </li>
                    <li>Nozioni di Project Management</li>
                    <li>Buona conoscenza della lingua Inglese.</li>
                  </ul>
                  <p>Offriamo:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Contratto di assunzione a tempo indeterminato con incentivi
                      in base ai risultati raggiunti
                    </li>
                  </ul>
                  <p>Dettagli:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Sede: Veneto - Venezia Mestre</li>
                  </ul>

                  <h4 className={'title'}>Inviaci il curriculum</h4>
                  <FormContactCareers position={'RIF.99 - Account executive'} />

                </div>
                <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default AccountExecutive
